#quienes-somos h2{
    margin-top: 50px;
    margin-bottom: 50px;
}

.pin-icon{
    height: 100px;
}

@media (max-width: 720px) {
    .pin-icon {
        height: 50px;
    }

    
}