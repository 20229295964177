#footer {
    color: white;
}

#top-footer {
    background-image: url('../imgs/footer/top-footer.png');
    background-position: bottom;
    background-size: cover;
}

#top-footer #text-top {
    padding-top: 15rem;
    padding-bottom: 5rem;
}

#bot-footer {
    background-color: #303030;
}

.text-footer {
    padding-top: 30px;
    padding-bottom: 30px;
}

.text-footer a {
    color: white;
    text-decoration: underline;
}

.a-p:hover{
    text-decoration: underline;
    cursor: pointer;
}

.text-footer #url-bmb {
    font-size: 15px;
}

.text-footer a:hover {
    color: white;
    cursor: pointer;
}

@media (max-width: 750px) {

    #top-footer #text-top {
        padding-top: 5rem;
        padding-bottom: 5rem;
    }

    #top-footer h2{
        font-size: 20px;
    }
    #top-footer h3{
        font-size: 15px;
    }
}