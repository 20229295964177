#servicios {
    margin-top: 100px;
    background-image: url('../imgs/background-servicios.png');
    background-position: bottom;
    background-size: cover;
}

#servicios .top-servicios{
    margin-top: 50px;
}

#servicios .top-servicios p {
    margin-top: 50px;
    color: white;
}

#servicios img {
    margin-top: 30px;
    max-width: 150px;
}

#servicios .card {
    background: rgba(255, 255, 255, 0.8);
    border-radius: 20px;
    margin-bottom: 50px;
    min-height: 450px;
}

ul li{
    color: #043071;
}

.sub-points li{
    color: black !important;
}