#header-kj {
    background-position: center;
    height: 800px;
    overflow: hidden !important;
}

.header-video {
    width: 100%;
    padding: 0px;
    object-fit: cover;
    top: 0;
    left: 0;
}

#header-kj .logo-header {
    position: absolute;
    padding-top: 150px;
}

@media (max-width: 1200px) {

    #header-kj {
        height: 500px;
    }
}

@media (max-width: 1000px) {

    .header-video{
        position: relative;
    }

    #header-kj {
        height: 400px;
    }

    #header-kj .logo-header {
        padding-top: 150px;
    }
}

@media (max-width: 720px) {
    #header-kj {
        height: 300px;
    }

    #header-kj .logo-header {
        padding-top: 130px;
    }
}

.vp-unmute{
    display: none !important;
    visibility:hidden !important;
}