/* Top Navbar */
#top-navbar {
  background-color: #278196;
}

.text-topnavbar {
  display: flex;
  justify-content: space-between;
  padding-top: 5px;
  font-size: 12px;
  color: white;
}

.right-align {
  margin-left: auto;
  padding-right: 10px !important;
}

.text-topnavbar p {
  padding-left: 30px;
}

.text-topnavbar p:hover {
  cursor: pointer;
  transition: font-size 1s ease;
  text-decoration: underline;
  text-decoration-color: rgb(255, 255, 255);
  text-decoration-thickness: 2px;
  font-size: 15px;
}

.text-topnavbar img {
  padding-left: 10px;
}

.text-topnavbar img:hover {
  cursor: pointer;
  transform: scale(1.5);
  transition: all 0.5s ease-in-out;
}

/* Estilos para pantallas menores a 670px */
@media (max-width: 670px) {
  .text-topnavbar p {
    text-align: center;
    font-size: 12px;
  }

  .text-topnavbar p:hover {
    font-size: 13px;
    cursor: pointer;
  }

  .navbar-brand img {
    height: 40px;
  }
}

/* Estilos para pantallas menores a 576px */
@media (max-width: 550px) {
  .text-topnavbar {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    color: white;
  }

  .text-topnavbar p {
    text-align: center;
    font-size: 10px;
  }

  .redes{
    height: 15px;
    flex: 1;
  }

  .text-topnavbar p:hover {
    font-size: 13px;
    cursor: pointer;
  }
}

/* Estilos para pantallas menores a 576px */
@media (max-width: 455px) {
  .redes{
    height: 15px;
    flex:1 1 30%
  }
}

/* Navbar */

.full-navbar {
  background-color: #ffffff;
  left: 0;
  top: 0;
  right: 0;
  position: fixed;
  width: 100%;
  z-index: 99;
}

.nav-item .active {
  font-style: unset;
  border-bottom: 2px solid rgb(205, 0, 0)
}

.nav-item .nav-link:hover {
  font-style: unset;
  border-bottom: 2px solid rgb(205, 0, 0);
}

.nav-item{
  cursor: pointer;
}