#cobertura-aduanal{
    margin-top: 100px;
}

#cobertura-aduanal img{
    max-width: 60%;
}

@media (max-width: 800px) {
    #cobertura-aduanal img{
        max-width: 100%;
    }
}