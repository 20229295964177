#nuestro-valor h2{
    margin-top: 50px;
    margin-bottom: 50px;
}

.card-valor{
    background-color: #278196;
    min-height: 450px;
}

.card-valor img{
    max-width: 300px;
    height: auto;
    padding: 50px;
}

.card-valor p{
    color: white;
    margin-right: 20px;
    margin-left: 20px;
}

@media (max-width: 750px) {
    .card-valor{
        background-color: #278196;
        min-height: 400px;
    }

    .card-valor img{
        max-width: 200px;
    }
}