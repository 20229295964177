@import url('https://fonts.googleapis.com/css2?family=Inter:wght@200;700&family=Open+Sans&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100&display=swap');

body {
  font-family: 'Open Sans', sans-serif;
}

h2 {
  font-family: 'Inter' !important;
}

#main {
  margin-top: 120px;
}

.btn-primary {
  background-color: #043071;
}

.btn-primary:hover {
  background-color: #278196;
}

.btn-primary:focus {
  background-color: #278196;
}

.whatsapp-btn {
  position: fixed;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 1rem;
}

.whatsapp-btn img {
  height: 70px;
}

@media (max-width: 720px) {
  .whatsapp-btn img {
    height: 60px;
  }
}

.whatsapp-btn:hover {
  transform: scale(1.2);
  transition: all 0.5s ease-in-out;
}

/* Custom scrollbar */
body::-webkit-scrollbar {
  width: 0.5rem;
  background-color: #ffffff;
}

body::-webkit-scrollbar-thumb {
  background-color: #278196;
}

body::-webkit-scrollbar-track {
  background-color: #ffffff;
}

@import url('./css/navbar.css');

@import url('./css/header.css');

@import url('./css/quienes-somos.css');

@import url('./css/servicios.css');

@import url('./css/cobert-aduanal.css');

@import url('./css/nuestro-valor.css');

@import url('./css/mision-vision.css');

@import url('./css/footer.css');

@media (max-width: 720px) {
  section h2 {
    font-size: 30px;
  }
}